form {
	label {
		color: #fff;
		font-size: 1.2rem;
	}
	input[type="text"] {
		font-size: 1.15rem;
		color: $cm-70-black-color;
		font-family: myriad-pro-condensed, sans-serif;
		text-transform: uppercase;
	}
	button.large {
		font-size: 2rem;
	}
}
