.btn-no-description {
	h2 {
		border-bottom: none !important;
		margin-bottom: 0 !important;
	}
}
.conmet-button {
	@include border-radius(10px);
	@include button-style(
	$background: $cm-red, // Background color
	$color: #fff, // Text color
	$style: solid // Can be solid or hollow
	);
	cursor: pointer;
	border: none;
	margin: 1.25rem 0;

	a, button {
		padding: 0rem 1rem;
		width: 100%;
		background: none;
		border: none;
	}

	h2 {
		color: #fff;
		text-align: left;
	  border-bottom: 2px solid scale-color($cm-red, $lightness: 30%);
		font-size: 1.55rem;
		padding: 5px 0;
		i {
			float:right;
			position: relative;
			top: 0px;
			right: 0px;
			padding: 0px;
			font-size: 1.3em;
			margin: 0;
			line-height: 1.2rem;
		}
	}

	a {
		color: #fff;
	}

}

.active {
	background-color: scale-color($cm-red, $lightness: -25%)!important;
}

.small-conmet-button {
	@include border-radius(10px);
	@include button-style(
	$background: $cm-red, // Background color
	$color: #fff, // Text color
	$style: solid // Can be solid or hollow
	);
	cursor: pointer;
	margin: 1.25rem;
	padding: 1rem;
	border: none;
	text-align: center;
	a, button {
		text-align:center;
		padding: 1rem;
		width: 100%;
		background: none;
		border: none;
	}
}
.center {
	text-align: center;
	a, button {
		width: 100%;
		text-align: center;
	}
}
.disabled {
	@include button-style(
	$background: $cm-mid-grey, // Background color
	$color: #fff, // Text color
	$style: solid // Can be solid or hollow
	);
	cursor: default;
	h2 {
		border-bottom: 2px solid scale-color($cm-dark-grey, $lightness: 30%);
	}
	a,button {
		pointer-events: none;
   	cursor: default;
	}
}
.disabled:hover {
	background-color: $cm-mid-grey
}
.general-button {
	display: block;
	margin-left: auto;
	margin-right: auto;
	font-size: 1.7rem;
	text-align: center;
	@include border-radius(10px);
	@include button-style(
	$background: $cm-red, // Background color
	$color: #fff, // Text color
	$style: solid // Can be solid or hollow
	);
	cursor: pointer;
	width: 100%;
	margin: 1.25rem 0;
	padding: 1rem .8rem;
	letter-spacing: 0.04rem;
	font-family: myriad-pro-condensed, sans-serif;
	text-transform: uppercase;
}

.truck-make {
	font-size: 1.3rem;
}

.yes-no-button {
	font-family: myriad-pro-condensed, sans-serif;
	text-align: left;
	font-size: 1rem;
	padding: .75rem !important;
	a {
		color: #fff !important;
		font-size: .7rem;
	}
	em {
		border-right: 1px solid $cm-dark-red;
		font-size: 2rem;
		font-style: normal;
		text-transform: uppercase;
		padding-right: 10px;
		margin-right: 10px;
		vertical-align: middle;
	}
	span.yes {
		display: inline-block;
    width: 60%;
    vertical-align: middle;

	}
	// em {
	//
	// 	font-size: 2rem;
	// 	font-style: normal;
	// 	text-transform: uppercase;
	//
	// 	width: 30%;
	// 	display: inline-block;
	// }
	// span {
	// 	width: 70%;
	// 	display: inline-block;
	// 	vertical-align: top;
	// }

	&.bold{
		font-weight: bold;
	}
}
.big-button {
	h4 {
		margin-right: 0px;
		padding-right: 0px;
	}
	p {
		text-align: left;
		padding-bottom: 0px;
		line-height: 1.2rem;
		font-size: .9rem;
		margin-bottom: .5rem !important;
	}
	i {
		float: right;
		padding-top: 0px;
		// padding-left: 10px;
		// padding-right: 10px;
		// padding-bottom: 10px;
		font-size: 3.0em;
	}

}

.step-bar {
	width: 100%;

	a.back-btn, .step-number, .home-btn{
		color: $cm-dark-grey !important;
		margin-top:5px !important;
		padding-top:4px !important;
		display: block;
		font-weight: bold;
		vertical-align: center;
	}
	.home-btn {
		text-align: right !important;
		padding-right: 20px;
	}

	.grid-content {
		padding: 0px;
		background: rgba(220, 221, 222, 0.7);
		height: 39px;
	}

	.step-number {
		text-align: right;
		padding-right: 10px;
		color: $cm-mid-grey !important;
	}



}
.cm-button-group {
	text-align: center;
	margin-top: 1.5rem;
}

.result {
	@include clearfix;
	.prev-button, .next-button {
		font-size: 2.8rem;
		width: 10%;
		vertical-align:middle;
		padding-top: 100px;
		cursor: pointer;
		&.hide-button {
			opacity: 0;
			visibility: hidden;
		}
	}
	.disabled {
		color: #fff !important;
		background-color: transparent !important;
		cursor: default;
		visibility: hidden;
	}
	.prev-button {
		float: left;
	}
	.next-button {
		float: left;
	}
	.details {
		width: 80%;
		float: left;
		p {
		text-align: center;
		color: $cm-mid-grey;
		font-size: .9rem;
		margin-bottom: 0.25rem;
		}

		// h2 {
		// 	font-size: 1.5rem;
		// }
	}
	.hide-button {
		i {
			color: #fff;
		}
	}
	.hub-name{
		font-size: 16px;
		text-transform: initial;
		font-family: myriad-pro, sans-serif;	
		ul{
			list-style-type: none;
			margin: 0;
			padding: 0;
		  }
	}
	.optional-spindle{
		display: block;
		width: 100%;
		margin: 9px 0;
		font-size: 15px;
		line-height: 22px;
		text-align: center;
		font-weight: bold;
		&.note {
			padding: 12px;
			background-color: #cacaca;
		}
	}
	.warranty-spindle{
		display: block;
		width: 100%;
		margin: 9px 0;
		font-size: 12px;
		line-height: 22px;
		text-align: center;
		font-weight: bold;
		&.note {
			padding: 12px;
			background-color: #cacaca;
		}
	}
	.note-addLinks{
		text-transform: uppercase;
		font-family: myriad-pro-condensed, sans-serif;}
	.disclaimer{
		font-size: 20px;
		color: #c00000;
		margin: 15px 0;
		text-align: center;
		font-weight: bold;
	}
}
.result-navigation {
	margin-top: 40px;
	text-align:center;
	@include clearfix;
	button {
		@include button-style(
		$background: $cm-dark-grey, // Background color
		$color: #fff, // Text color
		$style: solid // Can be solid or hollow
		);
		border-radius: 0px;
		border: 1px solid #fff;

		margin: 2px;
	}
	button.active {
		background-color: $cm-dark-red !important;
	}
}

/* Position and sizing of burger button */
.bm-burger-button {
	position: absolute;
	width: 36px;
	height: 30px;
	right: 26px;
	top: 28.525px
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
	background: $cm-dark-grey;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
	height: 24px;
	width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
	background: $cm-mid-grey;
}

/* General sidebar styles */
.bm-menu {
	//font-family: "ArialNovaCond";
	background: #fff;
	padding: 2.5em 1.5em 0;
	font-size: 1.15em;
	//border-left: solid 10px $cm-mid-grey;
	p{
		color: $cm-mid-grey;
	}
	ul {
		li {
			list-style: none !important;
		}
	}
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}
.include-text {
	color: #000000 !important;
	text-align: center;
	text-transform: inherit !important;
}
ul.dash {
	margin-left: 0;
	padding-left: 1em;
	color: #000000 !important;
}
ul.dash > li:before {
	display: inline-block;
	content: "-";
	width: 1em;
	margin-left: -1em;
	color: #000000 !important;
}

.small-6.btn-yes-no{
	flex: 0 0 50% !important;
	max-width: 50% !important;
}

@media only screen and (max-width: 767px) {
.small-6.btn-yes-no {
		flex: 0 0 100% !important;
		max-width: 100% !important;
	}
}

