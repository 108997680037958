body.rate {
  -webkit-transform: rotate(90deg);
}
body {
	display: -ms-flexbox;
	font-family: myriad-pro, sans-serif;
	background: $cm-diagonal-background;
}
body,html{
	height: auto;
}
.main-content {
	color: $cm-dark-grey;
	h1,h2,h3,h4,h5,h6 {
		color: $cm-dark-grey;
		text-align: center;
		font-family: myriad-pro-condensed, sans-serif;
	}
	.conmet-button h2{
	font-family: myriad-pro-condensed, sans-serif;
	}
	.terms-and-conditions{
		text-align: center;
		font-family: myriad-pro-condensed, sans-serif;
		a{
			text-decoration-line: underline;
		}
	}
	.text-kit{
		text-align: center;
	}
}
.grid-content{
	.step-bar{
		margin-left: 0rem;
		margin-right: 0rem;
	}
	.terms-and-conditions{
		text-align: center;
		font-family: myriad-pro-condensed, sans-serif;
		a{
			text-decoration-line: underline;
		}
	}
}
h2,h3,h4,h5,h6{
	//font-family: myriad-pro-condensed, sans-serif;
	letter-spacing: 0.02rem;
}

.mb-3 {
	margin-bottom: 30px;
}

.grid-content > .grid-block {
	flex-wrap: unset !important;
}

.global-navigation {
	background: #fff;
	// background: $cm-diagonal-background;
	padding: 1.5rem;
	text-align: center;
	ul {
		margin-left: 0;
    list-style: none;
	}
	li {
		list-style: none !important;

	}
}
.navigation-wrapper {
	position: relative;
	.sub-title {
		position: absolute;
		bottom: 5px;
		text-align: center;
		width: 100%;
		font-size: 1.7rem;
		color: #414042;
		font-style: normal;
		font-weight: 400;
		text-transform: uppercase;
		font-family: myriad-pro-condensed,sans-serif;
	}
}

.title-bar.dark {
	background: #fff;
	padding: 0.25rem;
	.note-dot{
		width: 10px;
		height: 10px;
		border-radius: 10px;
		position: absolute;
		right: 22px;
		top: 24px;
		background: #d31145;
		z-index: 1;
	}

}
.title-bar .center h2 {
	color: #fff;
	font-size: 2.2rem;
	padding-top: 1.4rem;


}
.grid-container {
	h1, h2,h3,h4,h5,h6 {
		text-transform: uppercase;
		color: $cm-80-black-color;
	}
	.meta{
		font-family: myriad-pro-condensed, sans-serif;
		text-transform: uppercase;
	}
	.specs td{
		text-transform: uppercase;
	}
	.help{
		font-family: myriad-pro-condensed, sans-serif;
	}
	.product-details{
		font-family: myriad-pro, sans-serif;
	}
}
#logo {
	img {
		width: 150px;
	}
}
a {
	p,h1,h2,h3,h4,i {
		color: $cm-dark-grey;
	}
}
.grid-block.footer {
	padding-top: 1.2rem;
	background: #000 !important;
	.grid-content {
		color: #fff;
		text-align: center;
	}
}
img.product-image {
	display: block;
    margin-left: auto;
    margin-right: auto;
	&.stud-image {
		object-fit: contain;
		width: 200px;
		height: 130px;
	}
}
img.eq-ht {
	min-height: 200px;
	max-height: 200px;
	margin-top: 10px
}

img.brake-rotor-flange, img.filter-image {
	display:block;
	margin-left: auto;
	margin-right: auto;
}

.error {
	color: $cm-error-color;
	//font-family: "ArialNovaCond";
	font-weight: bold;
	padding-top: 1.2rem;
}
.help {
	color: $cm-70-black-color;
	//font-family: "ArialNovaCond";
	font-size: 0.8rem;
	padding-left: 0.5rem;
	text-transform: uppercase;
}
.help2 {
	color: $cm-mid-grey;
	//font-family: "ArialNovaCond";
	font-size: 0.9rem;
}
// Splash
.splash {
	background: rgb(220,221,222);
	.splash-title {
		margin-top: 5rem;
		text-align: center;
		font-size: 3rem;
		padding: 2rem;
		background: #fff;
		h3 {
			color: $cm-mid-grey;
			font-size: 1.4rem;
		}
		h1 {
			font-size: 3.1rem;
			line-height: 2.9rem;
		}
		h2 {
			font-family: myriad-pro-condensed, sans-serif;
		}
	}
}
.splash-footer {
	#logo {
		img {
			width: 120px;
		}
	}
}

.modal-title{
	background: #d31145;
	.grid-block {
		.text-center h4{
			color: #ffffff;
		}
		.text-right {
			padding-right: 20px;
			h4{
				color: #ffffff;
				cursor: pointer;
			}
		}

	}
	.note-detail{
		padding: 5px;
		.back {
			display: flex;
			align-items: center;
			cursor: pointer;
			h5{
				color: #ffffff;
			}
		}
		.note-title{
			h4 {
				color: #ffffff;
			}
		}
	}
}


.ReactModal__Overlay{
	z-index: 1;
}

.note-count{
	border-radius: 10px;
	background: #d31145;
	color: #ffffff;
	font-size: 12px;
	padding: 0 5px;
}


.notification-modal{
	width: 25%;
	margin: 10% auto;
	border: 1px solid #cccccc;
	background: #fff;
	outline: none;
	overflow-y: hidden;
	word-break: break-word;

	.modal-content{
		min-height: 300px;
		padding: 20px;
	}
	.notification-slider {
		position: relative;
		.slick-arrow {
			border: solid #d31145;
			border-width: 0 5px 5px 0;
			padding: 10px;
			outline: none;
			cursor: pointer;
			margin-bottom: 30px;
			background: #ffffff;
		
			&.slick-prev {
				transform: rotate(135deg) !important;
				background: #ffffff;
			}
			&.slick-next {
				transform: rotate(-45deg) !important;
				margin-right: 10px;
				background: #ffffff;
			}
			&.disabled {
				opacity: 0.4;
				cursor: not-allowed;
				pointer-events: none;
			}
		}
		.note{
			outline: none;
			//h2{padding-bottom: 20px}
			.conmet-button{
				width: 50%;
				margin-left: 25%;
			}
			h2{text-transform: uppercase;
				 font-size:30px;
				 text-align: center;
				color: rgb(65, 64, 66);
				font-family: myriad-pro-condensed, sans-serif}
			h4{
				text-align: center; color: #FFFFFF; margin-bottom: 5px;font-size: 24.8px;font-family: myriad-pro-condensed, sans-serif;min-width: 102px;
			}
		}
		.slider-control-centerleft {
			top: 150px !important;
			left: unset !important;
		}
		.slider-control-centerright {
			top: 150px !important;
		}
	}
}

.note-detail{
	padding: 2rem;
	word-break: break-word;
	h2{text-transform: uppercase;color: rgb(65, 64, 66);font-family: myriad-pro-condensed, sans-serif;line-height: 37px}
	h4{color: rgba(0, 0, 0, 0.7);font-family: myriad-pro-condensed, sans-serif; text-align: center; line-height: 23px; text-transform: initial; padding: 30px 0}
	ul{
		list-style-type:none;
		margin-left: 0;
		li{font-family: myriad-pro-condensed, sans-serif;font-size: 23px}
	}

}

@media only screen and (max-width: 1680px) {
	.notification-modal{
		width: 25%;
		margin: 15% auto;
	}
}
@media only screen and (max-width: 1200px) {
	.notification-modal{
		width: 40%;
		margin: 20% auto;
	}
}
@media only screen and (max-width: 767px) {
	.notification-modal{
		width: 85%;
		margin: 35% auto;
	}
}
@media only screen and (max-width: 367px) {
	.notification-modal {
		width: 90%;
		margin: 50% auto;
	}
}
.notification-menu.grid-frame{overflow: visible}

.notification-menu {
	background: initial;
	.grid-block{
		width: 100%;
		overflow: auto;
		flex-wrap: nowrap;
		.splash-title{
			margin: 0;
			background: initial;
			//min-width: 400px;
			.notification-list{
				padding-top: 10px;
			}
			.note-arrow{
				border-top: 15px solid transparent;
				border-bottom: 15px solid transparent;
				border-left: 15px solid #d31145;
				position: absolute;
				top: 5px;
				left: 50px;
			}
			.note-list{
				padding-left: 100px;
				h4{
					font-family: myriad-pro-condensed, sans-serif;
					font-size: 24px;
				}
			}
		}
	}
}


// Details
.meta {
	position: relative;
	padding: 1.3rem;
	color: $cm-70-black-color;
	background: #fff;
	@include clearfix;
	font-size: 1.7rem;
	em {
		color: $primary-color;
	}
	img {
		display: inline-block;
		text-align: right;
		float: right;
	}
}
.meta:before {
    content: '';
    position: absolute;
    top: 0; right: 0;
    border-top: 20px solid $cm-dark-grey;
    border-left: 20px solid #fff;
    width: 0;
}

.unitized{
	padding: 20px;
	.details {
		width: 100%!important;
		.row{
			text-align: center; padding: 20px;
			img{margin: 0 15px}
		}
	}
}
.description {
	//font-family: "Arial";
	margin-top: .2rem;
	background: #fff;
	color: $cm-mid-grey;
	padding: 1.3rem;
	text-align: justify;
	font-size: 0.7rem;
}

.specs, .parts {
	position: relative;
	padding: 1rem;
	h2 {
		color: $cm-90-black-color;
		text-align: left;
	}
	background: #F2F2F2;
	.badge {
		margin-right: 5px;
		font-weight: bold;
	}

	table {
		display: table;
		width: 100%;
		display: block;
		margin-left: auto;
		margin-right: auto;
		tbody {
			display: table;
			width: 100%;
		}
		tr {
			width: 100%;
			border-bottom: 1px solid #ccc;
			td {
				font-size: 1.2rem;
				padding: 1.0rem 0.5rem 1rem 0;
			}
			td:first-child {
				padding-left: 0.5rem;
				color: $cm-60-black-color;
			}
			td:last-child {
				color: $cm-70-black-color;
				max-width: 230px;
				min-width: 100px;
			}
		}
		tr:last-child {
			border-bottom: none;
		}
	}
}

.parts {
  h2 {
		color: $cm-red !important;
		text-align: left;
    margin-top: 1.2rem;
    margin-bottom: 1.2rem;
	}
  table {
		display: table;
		width: 100%;
		display: block;
		margin-left: auto;
		margin-right: auto;

		tbody {
			display: table;
			width: 100%;
      border-bottom: 1px solid #ccc;
		}
		tr {
      border-top: 1px solid #ccc;
      td:first-child {
				color: $cm-table-desc;
        width: 100%;
			}

		}
	  td {
		  tbody {
			  border: none;
			  tr {
				  border: none;
				  td {
					  padding: 0;
				  }
			  }
		  }
	  }
	}
}

.specs:before, .parts:before, .stud-chart:before {
		content: '';
		position: absolute;
		top: 0; right: 0;
		border-top: 20px solid #fff;
		border-left: 20px solid $cm-mid-grey;
		width: 0;
}
.stud-chart:before {
	content: none;
}
.figure {
	text-align: center;
	margin-bottom: 1.3rem;
	img {
		padding-bottom: 10px;
	}
	small {
		text-align:center;
		margin:1.2rem;
		padding-top: 10px;
		font-size: 1.0rem;
	}
}
.hidden {
	display:none !important;
}

div.disclaimer {
  margin-top: 30px;
  p{
    font-size: .90rem;
    color: $cm-mid-grey;
  }
}
div.not-found {
  margin: 30px;
  p{
    color: $cm-dark-grey;
	font-family: myriad-pro-condensed, sans-serif;
  }
}
h2.partsSubHead {
  color: $cm-mid-grey !important;
	font-family: myriad-pro-condensed, sans-serif;
  span.number {
    color: $cm-red !important;
	font-style: italic;
  }
}

#disclaimer {
	background: #fff;
	.splash-title{
		margin: 0;
	}
}
.hub-compare{
	#autoComplete{
		margin-top: -10px!important;
	}
	h2{
		margin: .7em 0;
		font-family: myriad-pro-condensed,sans-serif;
		text-transform: uppercase;
	}
	input{
		margin: 2em 0;
		font-family: myriad-pro-condensed,sans-serif;
		text-transform: uppercase;
	}
	.menu{
		z-index: 9999;
		max-height: 198px!important;
		.item{
			min-height: 0!important;
			padding-left: 0!important;
			h6{
				font-size: 13px;
			}
		}
	}
	.grid-block{
		.grid-content{
			margin: 10px 0;
		}
	}
}

.serviceComponent{
	display: flex;
	justify-content: center;
	.general-button{
		width: 50%;
		font-size: 16px;
		padding: 12px;
		margin-top: 10px;
		//text-transform: initial;
		//font-weight: bold;
	}
}

#autoComplete{
	input{
		margin: 0;
		&::placeholder{
			color: $cm-40-black-color;
		}
	}
	.menu{
		position: absolute;
		width: 100%;
		border-left: 1px solid #ccc;
		border-right: 1px solid #ccc;
		max-height: 426px;
		overflow: auto;
		box-shadow: 0 5px 5px -3px rgba(0,0,0,.2), 0 8px 10px 1px rgba(0,0,0,.14), 0 3px 14px 2px rgba(0,0,0,.12);
		.menu-item{
			color: $cm-mid-grey;
			text-transform: uppercase;
			padding: 8px 10px;
			border-bottom: 1px solid #ccc;
		}
		.item{
			min-height: 40px;
			background: #fff;
            line-height: 40px;
			padding-left: 15px;
		}
	}
}

.flex-row{display: -webkit-flex;display: flex;-webkit-flex-flow: row wrap;-ms-flex-flow: row wrap;-moz-flex-flow: row wrap;flex-flow: row wrap;}

#hubAssemblyResult{
	font-weight: bold;
	h1{font-size: 2.125rem;}
	.grid-content{
		padding: 0;
		text-align: center;
		.small-12{
			.details {
				min-height: inherit !important;
				ul {
					margin: 0 auto !important;
					width: 49%;
				}
			}
		}
		.note-compare{
			text-transform: uppercase;
			font-family: myriad-pro-condensed, sans-serif;
			padding-top: 20px!important;
			color: #6d6e71;
			font-size: .9rem;
			font-weight: initial;
			line-height: 23px;
		}
		.small-6,.small-12{
			float: left;
			padding: 0 5px;
			img{margin-bottom: 10px;width: 100%;background-size: contain !important;max-width: 350px;}
			.general-button{font-weight: normal;font-size: 1.2rem;}
			.type{
				font-family: myriad-pro-condensed, sans-serif;
				font-size: 27.2px;
				line-height: 27.2px;
				font-weight: initial;
				color: rgba(0, 0, 0, 0.7);
			}
			.number{
				font-family: myriad-pro-condensed, sans-serif;
				font-size: 27.2px;
				line-height: 27.2px;
				font-weight: initial;
				color: rgba(0, 0, 0, 0.7);
			}
			.brake-type, .seal-nut{margin: 0;}
			.weight{margin-bottom: 5px;}
			.optional-spindle{
				display: block;
				width: 100%;
				margin: 9px 0;
				font-size: 18px;
				line-height: 22px;
				text-align: center;
				font-weight: bold;
				&.note {
					padding: 12px;
					background-color: #cacaca;
				}
			}
			
			.warranty-spindle{
				display: block;
				width: 100%;
				margin: 9px 0;
				font-size: 12px;
				line-height: 22px;
				text-align: left;
				div.small-6{
					float: none;
					text-align: center;
				}
			}


			.details{
				margin-top: 15px;
				min-height: 140px;
				div{
					font-family: myriad-pro-condensed, sans-serif;
					font-size: 24px;
					line-height: 24px;
					font-weight: initial;
					color: rgba(0, 0, 0, 0.7);}
				ul{
					margin: 5px 0 0 0;
					text-align: center;
					list-style: none;
					font-family: myriad-pro-condensed, sans-serif;
					font-size: 19px;
					line-height: 27.2px;
					font-weight: initial;
					color: rgba(0, 0, 0, 0.6);
				}
			}
			.compare-part-number {
				font-size: 27.2px;
				line-height: 27.2px;
				font-weight: initial;
				color: rgba(0, 0, 0, 0.7);
				font-family: myriad-pro-condensed,sans-serif;
			}
			.italic{
				font-style: italic;
				color: rgb(211, 17, 69);
			}
		}
		.optional-spindle{
			display: block;
			width: 100%;
			margin: 9px 0;
			font-size: 18px;
			line-height: 22px;
			text-align: left;
			div.small-6{
				float: none;
				text-align: center;
			}
		}
		.warranty-spindle{
			display: block;
			width: 100%;
			margin: 9px 0;
			font-size: 15px;
			line-height: 22px;
			text-align: left;
			div.small-6{
				float: none;
				text-align: center;
			}
		}

		.compare-props-wrapper {
			width: 100%;
			background-color: #fff;
			border: none;
			display: flex;
			flex-direction: column-reverse;
			margin-top: 20px;
			.compare-item {
				border-bottom: 4px solid #fff;
				order: -1;
				.content {
					display: flex;
					div.small-6{
						color:rgba(0, 0, 0, 0.6);
						font-weight: initial;
						font-size: 17px;
					}
				}
				.accordion-title {
					color: rgba(0, 0, 0, 0.7);
					font-weight: initial;
					font-size: 17px;
					position: relative;
					text-transform: uppercase;
					.pointer {
						position: absolute;
						top: 50%;
						right: 0;
						transform: translateY(-50%);
						border-width: 10px 7px 0 7px !important;
						border-color: $primary-color transparent transparent transparent !important;
						cursor: pointer;
					}
				}
			}
		}
	}
	.general-button{
		font-weight: initial;
	}
	.disclaimer{
		font-size: 20px;
		color: #c00000;
		margin: 15px 0;
		text-align: center;
	}
	.note{
		font-size: 14.4px;
		text-align: center;
		margin-bottom: 10px;
		text-transform: uppercase;
		font-family: myriad-pro-condensed, sans-serif;
		font-weight: initial;
		line-height: 23.04px;
	}

}

.replacement-drum {
	.conmet-button a {display: block;}
	.details {float: none !important; width: 60% !important; margin: auto !important;}
}

//.rebuild-kit-toggle{
//	width: 0;
//	height: 0;
//	border-style: solid;
//	border-width: 10px 0 10px 17.3px;
//	border-color: transparent transparent transparent $primary-color;
//	display: inline-block;
//	margin-right: 10px;
//	cursor: pointer;
//	&.show {
//		border-width: 17.3px 10px 0 10px !important;
//		border-color: $primary-color transparent transparent transparent !important;
//	}
//}
.rebuild-kit-toggle{
	position: relative;
	padding-left: 25px;
	cursor: pointer;
	display: block;
}
.rebuild-kit-toggle:before{
	cursor: pointer;
	content: "\002B";
	font-size:30px;
	font-weight: 900;
	color: $primary-color;
	position: absolute;
	bottom: 0;
	left: 0;
	top:-5px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.rebuild-kit-toggle.show:before{
	content: "\2012"
}

.rebuild-kit-info{
	background: #fff;
	opacity: 0;
	height: 0;
	transition: all 0.4s ease-in-out;
	display: flex;
	flex-direction: column;
	justify-content: center;
	overflow: hidden;
	&.show {
		opacity: 1;
		height: 110px;
		margin-top: 15px;
		tr{
			display: flex;
			border-bottom: none;
			border-top: none;
		}
		tr td:first-child{
			text-indent: 5%;
		}
		tr td{
			padding: 0.5rem 0.5rem 0.5rem 0!important;
		}
		tr .p-12{padding: 12px!important;}
	}
	&.show1 {
		height: auto;
	}
	&.m-0 {
		margin-top: 0 !important;
	}
	div {
		display: flex;
		justify-content: space-evenly;
		padding-bottom: 10px;
		.text {
			color: rgba(0, 0, 0, 0.6);
			width: 60%;
			padding-left: 10px;
		}
		.number {
			color: rgba(0, 0, 0, 0.7);
			width: 40%;
			padding-left: 16px;
		}
	}
}

.hub-name .bold {font-family: myriad-pro-condensed,sans-serif;
	font-size: 24px;
	line-height: 24px;
	font-weight: 400;
	color: rgba(0,0,0,.7);}

#studResult {
  .result {
    //display: flex;
  }

  .specs.stud {
    background: none;
    padding: 0;
    margin-top: 15px;

    &:before {
      content: none;
    }
  }

  .prev-button, .next-button {
    //display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    width: auto;
    display: none;
  }
  
  

}
.stud-chart {
  position: relative;
  padding: 1rem;
  text-transform: uppercase;
  h2 {
	  color: $cm-copy-dark;
	  text-align: center;
  }
  background: #F2F2F2;
  .badge {
	  margin-right: 5px;
	  font-weight: bold;
  }
  table {
	  display: table;
	  width: 100%;
	  display: block;
	  margin-left: auto;
	  margin-right: auto;
	  tbody {
		  display: table;
		  text-align: center;
		  width: 100%;
	  }
	  tr {
		  width: 80%;
		  border-bottom: 1px solid #ccc;
		  text-align: center;
		  td {
			  padding: 1.0rem 0.5rem 1rem 1rem;
			  color: $cm-table-desc;
		  }
		  th {
			  padding: 1.0rem 0.5rem 1rem 1rem;
		  }
		  td:first-child {
			  padding-left: 1rem;
		  }
	  }
	  tr:last-child {
		  border-bottom: none;
	  }
	  .selected {
		td {
			color: $cm-red;
		}
	  }
  }
 }

.product-image.danger {
	width: 80%;
	max-width: 350px;
	margin: 20px auto;
}
.calculator {
	input[type="number"], p, small {
		text-align: center;
	}
	h1 {
		font-size: 2.2em;
	}
	h2 {
		margin: .7em 0;
	}
	.ffc {
		font-family: myriad-pro-condensed, sans-serif;
		color: #cccccc;
	}
	small {
		display: block;
		font-size: .95rem;
	}
	table {
		margin: 30px 0;
		font-family: myriad-pro, sans-serif;
		&.example {
			td {
				border: 1px solid #666;
				&.table-center {
					text-align: center;
				}
			}
		}
	}
	td {
		padding: 10px;
	}
	#results {
		table {
			width: 90%;
			margin: 0 auto;
			border: 2px solid #414042;
			thead {
				border-bottom: 1px solid #414042;
				th {
					border-right: 1px solid #414042;
				}
			}
			tr {
				&.large, &.detail {
					border-bottom: 1px solid #414042;
					td {
						border-right: 1px solid #414042;
						&:first-child{
							padding: 10px;
						}
					}
				}
				&.large {
					td {
						padding: 10px;
						text-align: left;
						font-weight: bold;
						font-style: normal;
						font-size: 1.35rem;
					}
				}
				&.detail {
					td {
						color: #6d6e71;
					}
				}
			}
			td {
				white-space: nowrap;
				width: 33.33%;
			}
		}
		th {
			padding: 10px;
			text-align: left;
			font-weight: normal;
			font-style: normal;
			font-size: 2.75rem;
			text-transform: uppercase;
		}
	}
	.margin-holder {
		h2 {
			font-size: 2.2rem;
		}
	}
	.benefits {
		text-align: center;
		font-size: 1.5rem;
		line-height: 2rem;
		text-transform: uppercase;
		font-family: myriad-pro-condensed, sans-serif;
	}
	span {
		&.plus {
			font-weight: bold;
			font-size: 2.5rem;
			color: #d31145;
		}
	}
	.email-form {
		.email-group {
			.title {
				margin: 20px 0 10px;
				color: #414042;
				font-size: 1.2rem;
			}
			input {
				margin: 15px 0;
				font-size: 1.2rem;
				color: #414042;
			}
		}
		textarea {
			font-size: 1.2rem;
		}
	}
	form {
		input {
			font-family: inherit !important;
			text-transform: none !important;
		}
	}
}
.not-found {
	#logo {
		text-align: center;

		img {
			width: 200px !important;
		}
	}
	.title {
		margin-top: 1rem;
		text-align: center;
		font-size: 3rem;
		padding: 2rem;
		h1 {
			font-family: myriad-pro-condensed,sans-serif;
			font-size: 3.1rem;
			line-height: 3.5rem;
		}
	}
}

.optional-spindle-note{
	display: block;
	width: 100%;
	margin: 9px 0;
	font-size: 15px;
	line-height: 22px;
	text-align: center;
	font-weight: bold;
	&.note-center {
		padding: 12px;
		background-color: #cacaca;
	}
}

@media only screen and (max-width: 767px) {
	#hubAssemblyResult .grid-content .optional-spindle .warranty-spindle div.small-6 {
		flex: none;
		max-width: 100%;
	}

	#hubAssemblyResult .grid-content .small-6 .compare-part-number {
		text-align: left;
	}

	.rebuild-kit-info{
		div {
			.number {
				padding-left: 0;
			}
		}
	}
}
