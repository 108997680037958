// @font-face {
//   font-family: 'conmet-icons';
//   src: url('../fonts/conmet-icons.eot?4833579');
//   src: url('../fonts/conmet-icons.eot?4833579#iefix') format('embedded-opentype'),
//        url('../fonts/conmet-icons.svg?4833579#conmet') format('svg');
//   font-weight: normal;
//   font-style: normal;
// }
@font-face {
  font-family: 'conmet-icons';
  src: url('../../fonts/conmet-icons.eot?44012786');
  src: url('../../fonts/conmet-icons.eot?44012786');
  src: url('../../fonts/conmet-icons.eot?44012786#iefix') format('embedded-opentype'),
       url('../../fonts/conmet-icons.woff?44012786') format('woff'),
       url('../../fonts/conmet-icons.svg?44012786#conmet') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'conmet';
    src: url('../font/conmet.svg?4833579#conmet') format('svg');
  }
}
*/

 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "conmet-icons";
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
.icon-menu:before { content: '\e800'; } /* '' */
.icon-angle-right:before { content: '\e801'; } /* '' */
.icon-angle-left:before { content: '\e802'; } /* '' */
.icon-angle-up:before { content: '\e803'; } /* '' */
.icon-angle-down:before { content: '\e804'; } /* '' */
.icon-down-dir:before { content: '\e805'; } /* '' */
.icon-up-dir:before { content: '\e806'; } /* '' */
.icon-left-dir:before { content: '\e807'; } /* '' */
.icon-right-dir:before { content: '\e808'; } /* '' */

.spinner {
  margin: 20px auto;
  width: 55px;
  height: 40px;
  text-align: center;
  font-size: 10px;
}

.spinner > div {
  background-color: #efefef;
  height: 100%;
  width: 6px;
  display: inline-block;
  margin-right: 1px;
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.spinner .rect3 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% { -webkit-transform: scaleY(0.4) }
  20% { -webkit-transform: scaleY(1.0) }
}

@keyframes sk-stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }  20% {
    transform: scaleY(1.0);
    -webkit-transform: scaleY(1.0);
  }
}
