$body-background: #fff;
$button-radius: 4px;
$primary-color: rgba(186,12,47, 1);

$cm-dark-grey: #414042;
$cm-mid-grey: #6d6e71;
$cm-light-grey: #F2F2F2;
$cm-red: $primary-color;
$cm-dark-red: darken($cm-red, 8%);
$cm-copy-light: #fff;
$cm-copy-dark: #000;
$cm-table-desc: #9E9E9E;
$cm-table-detail: $cm-mid-grey;
$cm-error-color: #bfa043;
$cm-90-black-color: rgba(0,0,0,.9);
$cm-80-black-color: rgba(0,0,0,.8);
$cm-70-black-color: rgba(0,0,0,.7);
$cm-60-black-color: rgba(0,0,0,.6);
$cm-40-black-color: rgba(0,0,0,.4);

$cm-diagonal-background: linear-gradient(to bottom, #fff 50%, rgb(220, 221, 222) 100%);
#notification {
	height: auto;
}
.ie10 {
	.grid-frame {
		overflow: auto !important;
	}
	.grid-content {

	}

}
